<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>业务中台</el-breadcrumb-item>
           <el-breadcrumb-item>预售券</el-breadcrumb-item>
           <el-breadcrumb-item>订单列表</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
<!--    <el-row>-->
<!--      <template>-->
<!--        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">-->
<!--          <el-tab-pane  v-for="(item,index) in tabslist"  :key="index" :label="item.label" :name="item.name"></el-tab-pane>-->
<!--        </el-tabs>-->
<!--      </template>-->
<!--    </el-row>-->
    <el-row class="content-box">
      <!-- 表头 -->
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
              <label>姓名：</label>
              <el-input class="width-220"  v-model="realNames" placeholder="请输入真实姓名" clearable></el-input>
          </el-row>
          <el-row class="search-item">
              <label>电话：</label>
              <el-input class="width-220"  v-model="mobile" placeholder="请输入手机号" clearable></el-input>
          </el-row>

          <el-row class="search-item">
              <label>证件号：</label>
              <el-input class="width-220"  v-model="certificateNo" placeholder="请输入证件号" clearable></el-input>
          </el-row>
        </el-row>
        <el-row class="search-row">
          <el-row class="search-item">
              <label>预售券券码：</label>
              <el-input class="width-220" v-model="couponCode"  placeholder="预售券券码" clearable></el-input>
          </el-row>

          <el-row class="search-item">
              <label>使用类型：</label>
              <el-select v-model="state"  class="width-220" :placeholder="$t('msg.select')">
                <el-option
                    v-for="item in preSaleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
          </el-row>
          <el-button class="bg-gradient" type="primary" @click="handleQuery(true)" v-text="$t('msg.search')">搜索</el-button>
          <el-button  type="primary" plain @click="handleQuery(false)" v-text="$t('msg.reset')">重置</el-button>
        </el-row>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格 -->
        <el-table
            ref="mainTable"
            :data="tableData"
            border fit
            :stripe="true"
            v-loading="loading"
            >
          <el-table-column label="序号" width="50">
            <template slot-scope="scope">{{scope.$index+1}}</template>
          </el-table-column>
          <el-table-column prop="couponCode" label="预售券券码" min-width="50"></el-table-column>
          <el-table-column prop="mainTitle" label="预售券名称"></el-table-column>
          <el-table-column prop="presellPrice" label="预售价" min-width="40">
            <template slot-scope="scope">
              {{scope.row.presellPrice/100}}
            </template>
          </el-table-column>
          <el-table-column prop="marketPrice" label="商品价" min-width="40">
            <template slot-scope="scope">
              {{scope.row.marketPrice/100}}
            </template>
          </el-table-column>
          <el-table-column prop="realName" label="姓名" min-width="30"></el-table-column>
          <el-table-column prop="mobile" label="电话" width="115"></el-table-column>
          <el-table-column prop="certificateNo" label="证件号码"></el-table-column>
          <el-table-column prop="wecharId" label="微信号"></el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              {{scope.row.state | state}}/{{scope.row.orderStatus | orderstatus}}
            </template>
          </el-table-column>
          <el-table-column prop="distributorName" label="购买渠道" min-width="60"></el-table-column>
          <el-table-column prop="buyingTime" label="购买时间"></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
<!--               couponMyselfPage-->
              <el-button  type="text" @click="particulars(scope.row)" >
                <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
              </el-button>
              <el-button  type="text"  v-if="scope.row.state == 'NOT_USED' && scope.row.orderStatus != 'PENDING_PAYMENT' " @click="cancelAfterVerification(scope.row)">
                <el-link type="primary">核销</el-link>
              </el-button>
              <!-- <el-button type="text"  @click="zuofei(scope.row)">
                <el-link type="primary">作废</el-link>
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 预售券 -->
        <el-form label-width="105px" class="popup-box">
          <el-dialog title="预售券" :visible.sync="isShow" width="660px">
            <el-row class="tit">
              <h6>预售券</h6>
            </el-row>
            <el-row class="text-list">
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">购买人姓名</span>{{ details.realName }}</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">购买人电话</span>{{ details.mobile }}</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">证件号码</span>{{ details.certificateNo }}</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">微信号</span>{{ details.wecharId }}</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">购买渠道</span>{{ details.distributorName }}</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">购买时间</span>{{ details.buyingTime }}</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">状态</span>{{ details.state | state }}</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">核销时间</span></p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">预售券名称</span>{{ details.mainTitle }}</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">预售券简称</span>{{ details.subTitle }}</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">预售价格</span>{{ details.presellPrice/100 }} 元</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">商品原价</span>{{ details.marketPrice/100 }} 元</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">发行量</span>{{ details.issueCount }}张</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">单用户限购数</span>{{ details.limitQuantity }}份</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">开始时间</span>{{ details.activityBeginTime }}</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">结束时间</span>{{ details.activityEndTime }}</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">积分兑换</span>{{ details.isIntegral | isIntegral}}</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">过期退款方式</span>{{ details.refundType | refundType }}</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span class="width-100">有效期</span>截止时间 {{ details.validDate }}</p>
                </el-col>
                <el-col :span="12">
                  <p><span class="width-100">使用方式</span>{{ details.useType | useType }}</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" style="width: 100%;">
                  <p><span class="width-100">预购券简介</span>{{ details.remark }}</p>
                </el-col>
              </el-row>
            </el-row>
            <el-row class="btm-btn">
              <el-button class="bg-gradient"  type="primary" v-if="details.state == 'NOT_USED' && details.orderStatus != 'PENDING_PAYMENT' " @click="cancelAfterVerification(details)">核销</el-button>
              <el-button  @click="isShow = false">取消</el-button>
            </el-row>
          </el-dialog>
        </el-form>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" v-if="pageshow" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { urlObj } from "@/api/interface";
import { preSale } from "@/api/interface/business";
import { mapState } from 'vuex'
export default {
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.purchaseList()
            }
         }
      },
      hotelId:'purchaseList'
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  data(){
    return{
      total: 20,
      limit: '10',
      page:'1',
       pageshow:true,
      preSaleList: [
        // { id: 'UNCLAIMED', name: '待领取' },
        { id: 'NOT_USED', name: '未使用' },
        { id: 'USED', name: '已使用' }, { id: 'EXPIRE', name: '已过期' },
      ],
      tableData: [],
       loading: true,
      isShow: false,
      activeName: 'Paymented',
      tabslist:[
        {label:'已付款',name:'Paymented'},
        {label:'未付款',name:'Notpaying'}
      ],
      otherlist:[],
      state: '',
      // 提示文本
      del_success: "删除成功！",
      add_success: "添加成功！",
      update_success: '修改成功！',
      userName_no_null: '用户名不能为空！',
      mobile_no_null: '手机号不能为空！',
      nickName_no_null: '昵称不能为空！',
      confirm: '确定',
      cancel: '取消',
      confirm_remove: '确定移除？',
      prompt: '提示！',
      details:{},
      couponCode:'',
      memberId:'',
      mobile:'',
      hotelId:'',
      realNames:'',
      certificateNo:'',
      userInfo:'',
    }
  },
  mounted() {
    // this.userInfo = JSON.parse( sessionStorage.getItem('userInfo'))
    this.hotelId = this.hotelInfo.id
    this.purchaseList()
  },

  methods: {
    // zuofei(){
    //    this.$confirm('确定作废吗？', this.prompt, {
    //       confirmButtonText: this.confirm,
    //       cancelButtonText: this.cancel,
    //       type: 'warning'
    //    }).then(() => {
    //       this.$message({
    //          showClose: true,
    //          message: '活动作废成功！',
    //          type: 'success'
    //       })
    //    })
    // },
    //详情
    particulars(row){
      console.log(row)
      this.isShow = true
      this.details = row
    },
    purchaseList(){
      const url = preSale.couponMyselfPage
      let param = {
        page: this.page,
        limit: this.limit,
        couponCode:this.couponCode,
        hotelId:this.hotelId,
        Mobile:this.mobile,
        memberId:this.memberId,
        realName:this.realNames,
        certificateNo:this.certificateNo,
        state:this.state
      }
      this.$axios.post(url,param).then(res => {
        if (res.success) {
          this.total = res.total
          this.tableData = res.records
           this.loading = false
          // this.tableData =  this.otherlist.filter((item)=>{
          //   return item.orderStatus == 'HAVE_PAID'
          // });
          // this.total = this.tableData.length
        }
      })
    },
    cancelAfterVerification(row){
       //tab页面切换
       // handleClick(tab) {
       //   console.log(tab.index);
       //   if (tab.index == 1){
       //     this.tableData = this.otherlist.filter((item)=>{
       //       return item.orderStatus != 'HAVE_PAID'
       //     });
       //     this.total = this.tableData.length
       //   }else{
       //     this.tableData =  this.otherlist.filter((item)=>{
       //       return item.orderStatus == 'HAVE_PAID'
       //     });
       //     this.total = this.tableData.length
       //
       //   }
       // },
       //核销
       this.$confirm('确定进行核销吗？', this.prompt, {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: 'warning'
      }).then(() => {
        const url = preSale.useCouponMyself
        let param = {
          state:'USED',
          id:row.id,
          couponId:row.couponId
        }
        this.$axios.post(url, param, 'json').then(res => {
          if (res.status == 200){
            this.$message({
              showClose: true,
              message: '活动核销成功！',
              type: 'success'
            })
            this.purchaseList()
          }
        })
      })
    },
    // 改变每页数
    pageChange(num){
      this.limit = num
      this.purchaseList()
    },
    // 改变当前页
    handlePaging(num){
      this.page = num
      this.purchaseList()
    },
    handleQuery(bool){
      if (bool) {
         this.pageshow = false
         this.page = 1
         this.$nextTick(() => {
            this.pageshow = true
         })
        this.purchaseList()
      }else {
        this.couponCode = ''
        this.mobile = ''
        this.realNames = ''
        this.certificateNo = ''
        this.state = ''
      }
    },
    handlePop(){
      this.isShow = true
    }
  },
  filters:{
    state(val){
      if (val == 'UNCLAIMED'){
        return '待领取'
      }
      if (val == 'NOT_USED'){
        return '未使用'
      }
      if (val == 'USED'){
        return '已使用'
      }
      if (val == 'EXPIRE'){
        return '已过期'
      }
    },
    orderstatus(val){
      if (val == 'PENDING_PAYMENT'){
        return '待付款'
      }
      if (val == 'SUCCESSFUL_TRADE'){
        return '已完成'
      }
      if (val == 'HAVE_PAID'){
        return '已付款'
      }

    },
    isIntegral(val){
      if (val == 'NO'){
        return '积分不可兑换'
      }
      if (val == 'YES'){
        return '可用积分兑换'
      }
    },
    refundType(val){
      if (val == 'AUTOMATIC'){
        return '自动退款'
      }
      if (val == 'MANUAL'){
        return '手动退款'
      }
      if (val == 'REFUSE'){
        return '不可退款'
      }
    },
    useType(val){
      if (val == 'ONLINE'){
        return '线上预约'
      }
      if (val == 'OFFLINE'){
        return '线下使用'
      }
      if (val == 'ONLINE_OFFLINE'){
        return '线上线下都可使用'
      }
    }
  },

}
</script>

<style lang="scss">
.cont{min-width: 1500px !important;}
.popup-box{
  .tit{
    text-align: center;
    h6{ font-size: 18px; margin-bottom: 10px }
    p{ font-size: 16px }
  }
  .right-price{ text-align: right; span{ font-size: 26px; color: red } }
  .table-box{ width: 70%; margin: 0 auto }
  .text-list{
    margin: 20px auto 0; line-height: 30px; color: #666666;
    span{ display: inline-block; text-align: right; margin-right: 15px; color: #222222 }
  }
  .btm-btn{ margin: 20px 0 0 0; text-align: center }
}

.popup-box .text-list{line-height: 40px;}
/deep/ .el-tag--dark.is-hit{
  border-color: transparent;
}
.tag-pop .active .el-icon-check{
  display: inline;
}
.el-icon-check{
  display: none;
}
</style>
